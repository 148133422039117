export const LIFE_CYCLE_TYPES = {
  STARTED: 'started',
  ACTIVE: 'active',
  POST_INCIDENT: 'post_incident',
  CLOSED: 'closed',
};

export const LIFE_CYCLE_COLORS = {
  STARTED: 'red',
  ACTIVE: 'orange',
  POST_INCIDENT: 'blue',
  CLOSED: 'green',
};

export const useLifecycleMilestones = (lifecycles = []) => {
  return lifecycles.reduce((acc, curr) => {
    curr.milestones.forEach((milestone) => {
      acc.push({
        ...milestone,
        type: curr.type,
        label: milestone.name,
        value: milestone.slug,
        helpText: milestone.description,
        active:
          curr.type === LIFE_CYCLE_TYPES.ACTIVE ||
          curr.type === LIFE_CYCLE_TYPES.STARTED,
        color: LIFE_CYCLE_COLORS[curr.type.toUpperCase()],
      });
    });

    return acc;
  }, []);
};

export const getMilestonesByType = (milestones, type) => {
  return milestones.filter((milestone) => milestone.type === type);
};

export const getMilestoneBySlug = (milestones, slug) => {
  return milestones.find((milestone) => milestone.slug === slug);
};

export const getMilestonesByTypes = (milestones, types) => {
  return milestones.filter((milestone) => types.includes(milestone.type));
};

export const getActiveMilestones = (milestones) => {
  return getMilestonesByTypes(milestones, [
    LIFE_CYCLE_TYPES.ACTIVE,
    LIFE_CYCLE_TYPES.STARTED,
  ]);
};

export const getRetroMilestones = (milestones) => {
  return getMilestonesByTypes(milestones, [
    LIFE_CYCLE_TYPES.ACTIVE,
    LIFE_CYCLE_TYPES.STARTED,
    LIFE_CYCLE_TYPES.POST_INCIDENT,
  ]);
};

export const getActiveMilestonesWithFirstResolved = (milestones) => {
  const resolvedMilestones = getMilestonesByType(
    milestones,
    LIFE_CYCLE_TYPES.POST_INCIDENT,
  );

  if (resolvedMilestones.length) {
    return [...getActiveMilestones(milestones), resolvedMilestones[0]];
  } else {
    return getActiveMilestones(milestones);
  }
};

export const milestonesWithColorAndLabel = (milestones) => {
  return milestones.reduce((m, milestone) => {
    m[milestone.slug] = {
      ...milestone,
      color: LIFE_CYCLE_COLORS[milestone.type.toUpperCase()],
      label: milestone.name,
      value: milestone.slug,
    };

    return m;
  }, {});
};

export const getPostIncidentMilestones = (milestones) => {
  return getMilestonesByType(milestones, LIFE_CYCLE_TYPES.POST_INCIDENT);
};

export const getStartedMilestones = (milestones) => {
  return getMilestonesByType(milestones, LIFE_CYCLE_TYPES.STARTED);
};

export const isPostIncidentMilestone = (milestones, slug) => {
  return getPostIncidentMilestones(milestones)
    .map((milestone) => milestone.slug)
    .includes(slug);
};

export const isStartedOrActive = (milestones, slug) => {
  return getActiveMilestones(milestones)
    .map((milestone) => milestone.slug)
    .includes(slug);
};
export const isInactiveMilestone = (milestones, slug) => {
  const postIncident = getPostIncidentMilestones(milestones);
  const closed = getMilestonesByType(milestones, LIFE_CYCLE_TYPES.CLOSED);
  return [...postIncident, ...closed]
    .map((milestone) => milestone.slug)
    .includes(slug);
};

export const isClosedMilestone = (milestones, slug) => {
  return getMilestonesByType(milestones, LIFE_CYCLE_TYPES.CLOSED)
    .map((milestone) => milestone.slug)
    .includes(slug);
};

export const getFirstResolvedMilestone = (phases) => {
  const resolvedPhase = phases?.find(
    (phase) => phase.type === LIFE_CYCLE_TYPES.POST_INCIDENT,
  );
  return resolvedPhase?.milestones[0];
};

export const getFirstStartedMilestone = (phases) => {
  const startedPhase = phases?.find(
    (phase) => phase.type === LIFE_CYCLE_TYPES.STARTED,
  );
  return startedPhase?.milestones[0];
};

export const getLastActiveMilestoneForIncident = (
  milestones,
  incidentMilestones,
) => {
  const inactiveMilestones = getMilestonesByTypes(milestones, [
    LIFE_CYCLE_TYPES.CLOSED,
    LIFE_CYCLE_TYPES.POST_INCIDENT,
  ]).map((milestone) => milestone.slug);
  const active = incidentMilestones.filter((milestone) => {
    return !inactiveMilestones.includes(milestone.type);
  });
  return active[active.length - 1];
};
